	/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/09/2020 04:46
  	*/

@font-face {
    font-family: "Flaticon";
    src: url("../../assets/fonts/Flaticon.eot");
    src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
        url("../../assets/fonts/Flaticon.woff2") format("woff2"),
        url("../../assets/fonts/Flaticon.woff") format("woff"),
        url("../../assets/fonts/Flaticon.ttf") format("truetype"),
        url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-left-arrow:before { content: "\f100"; }
.flaticon-right-arrow:before { content: "\f101"; }
.flaticon-play-button:before { content: "\f102"; }
.flaticon-clock:before { content: "\f103"; }
.flaticon-call:before { content: "\f104"; }
.flaticon-cyber-security:before { content: "\f105"; }
.flaticon-testing:before { content: "\f106"; }
.flaticon-innovation:before { content: "\f107"; }
.flaticon-cybercrime:before { content: "\f108"; }
.flaticon-threat:before { content: "\f109"; }
.flaticon-cyber-security-1:before { content: "\f10a"; }
.flaticon-password:before { content: "\f10b"; }
.flaticon-authentication:before { content: "\f10c"; }
.flaticon-web-protection:before { content: "\f10d"; }
.flaticon-reliability:before { content: "\f10e"; }
.flaticon-cyber:before { content: "\f10f"; }
.flaticon-application:before { content: "\f110"; }
.flaticon-quote:before { content: "\f111"; }
.flaticon-database:before { content: "\f112"; }
.flaticon-security:before { content: "\f113"; }
.flaticon-security-1:before { content: "\f114"; }
.flaticon-pin:before { content: "\f115"; }
.flaticon-email:before { content: "\f116"; }
.flaticon-user:before { content: "\f117"; }
.flaticon-worldwide:before { content: "\f118"; }
.flaticon-key:before { content: "\f119"; }
.flaticon-padlock:before { content: "\f11a"; }
.flaticon-www:before { content: "\f11b"; }
.flaticon-url:before { content: "\f11c"; }
.flaticon-bug:before { content: "\f11d"; }
.flaticon-antivirus:before { content: "\f11e"; }