/* You can add global styles to this file, and also import other style files */
:root {
    --fontFamily: 'Lato', sans-serif;
    --bodyColor: #4b5280;
    --mainColor: #e21f36;
    --optionalColor: #ff5a00;
    --headingColor: #000219;
    --whiteColor: #ffffff;
    --blackColor: #000000;
    --fontSize: 16px;
    --transition: all ease .5s;
    --borderRadius: 4px;
    --boxShadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

html {
	scroll-behavior: smooth;
  }
  
body {
    font-size: var(--fontSize);
    font-family: var(--fontFamily);
	color: var(--bodyColor);

	&.body-color-three {
		background-color: #f5f7fa;
	}
}
.container {
	max-width: 1260px;
}
a {
	display: inline-block;
    transition: var(--transition);
	text-decoration: none;
	color: var(--bodyColor);

	&:hover {
        text-decoration: none;
        color: var(--mainColor);
	}
	&:focus {
		text-decoration: none;
	}
}
button {
    outline: 0 !important; 
	box-shadow: none;
	border: none;

    &:focus {
        box-shadow: none;
    }
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: var(--headingColor);
	font-weight: 800;
}
h3 {
	font-size: 20px;
	line-height: 1.4;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}
p {
    font-size: var(--fontSize);
	margin-bottom: 15px;
	line-height: 1.8;
	font-weight: 500;

	&:last-child {
        margin-bottom: 0;
    }
} 
img {
    max-width: 100%;
    height: auto;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.form-control {
    height: 50px;
    color: var(--mainColor);
	border: 1px solid #ebebeb;
	background-color: transparent;
	border-radius: 0;
	font-size: 16px;
	padding: 10px 20px;
	width: 100%;
	
	&::placeholder {
		color: #676a6a;
	}
	&:focus {
		color: var(--blackColor);
		background-color: transparent;
		box-shadow: unset;
		outline: 0;
		border: 1px solid var(--mainColor);
	}
}
.form-control:hover, .form-control {
    &:focus {
        box-shadow: unset;
    }
}
textarea {   
    &.form-control {
        height: auto;
    }
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.ptb-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.mt-100 {
	margin-top: 100px;
}
.mt-minus-100 {
	margin-top: -100px;
}
.mt-30 {
	margin-top: 30px;
}
.mb-30 {
	margin-bottom: 30px;
}

/*Bg-color Style*/
.bg-color {
	background-color: #f9fafb;
}
.white-bg {
	background-color: var(--whiteColor);
}

/*Default Btn Area Style*/
.default-btn {
	z-index: 1;
	font-weight: 600;
	font-size: 16px;
    color: var(--whiteColor);
	padding: 15px 30px 16px;
    line-height: 1;
    transition: var(--transition);
	text-align: center;
	background-color: var(--mainColor);
	position: relative;
	overflow: hidden;

	span {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 2px;
			height: 100%;
			background: var(--whiteColor);
			animation: top 2s linear infinite;
			animation-delay: 1s;
		}
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 2px;
			height: 100%;
			background: var(--whiteColor);
			animation: bottom 2s linear infinite;
			animation-delay: 1s;
		}
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: var(--whiteColor);
		animation: right 2s linear infinite;
	}
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: var(--whiteColor);
		animation: left 2s linear infinite;
	}
	&:hover {
		background-color: var(--optionalColor);
		color: var(--whiteColor);
	}
}
@keyframes left {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}
@keyframes right {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}
@keyframes top {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(-100%);
	}
}
@keyframes bottom {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(100%);
	}
}

/*Read More Btn Area Style*/
.read-more {
	font-weight: 600;
	font-size: 15px;
	color: var(--mainColor);

	i {
		position: relative;
		position: relative;
		top: 1.7px;
		margin-left: 5px;
	}
	&:hover {
		color: var(--mainColor);
	}
}

/*Section Title Area Style*/
.section-title {
	max-width: 750px;
	margin: -5px auto 50px;
	text-align: center;
	position: relative;

	span {
		display: block;
		margin-bottom: 12px;
		color: var(--mainColor);
		font-weight: 600;
	}
	h2 {
		font-size: 40px;
		margin-bottom: 15px; 
		position: relative;
		line-height: 1.4;

		&:last-child {
			margin-bottom: 0;
		}
	}
	&.white-title {
		span {
			color: var(--whiteColor);
		}
		h2 {
			color: var(--whiteColor);
		}
		p {
			color: var(--whiteColor);
		}
	}
}

/*keyframes*/
@keyframes border-transform {
	0%, 100% {
	  	border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
	}
	14% {
	  	border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
	}
	28% {
	  	border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
	}
	42% {
	  	border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
	}
	56% {
	  	border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
	}
	70% {
	  	border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
	}
	84% {
	  	border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
	}
}
@keyframes movebounce {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(30px);
	}
	100% {
		transform: translateY(0px);
	}
}
@keyframes movebounce-right {
	0% {
		transform: translateX(0px);
	}
	50% {
		transform: translateX(30px);
	}
	100% {
		transform: translateX(0px);
	}
}
@keyframes FocuS {
	0% {
	  opacity: 0;
	}
	75% {
	  opacity: 1;
	}
	100% {
	  opacity: 0;
	}
}

/*extra-css*/
.about-content {
	.nav-tabset {
		margin-bottom: 30px;

		li {
			display: inline-block;
			margin-right: 30px;
			
			span {
				font-weight: 700;
				font-size: 16px;
				display: block;
				color: var(--headingColor);
				border-bottom: 2px solid var(--headingColor);
				padding-bottom: 5px;
				cursor: pointer;
				transition: var(--transition);

				&:hover {
					border-color: var(--mainColor);
					color: var(--mainColor);
				}
			}
			&.active {
				span {
					border-color: var(--mainColor);
					color: var(--mainColor);
				}
			}
		}
	}
	.tabs-container {
		p {
			margin-bottom: 0;
		}
		ul {
			margin-top: 25px;

			li {
				margin-bottom: 10px;
				font-weight: 600;
				color: var(--headingColor);
				position: relative;
				font-size: 16px;
				padding-left: 25px;

				i {
					position: absolute;
					top: 4px;
					left: 0;
					font-size: 18px;
					color: var(--mainColor);
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.testimonials {
	.owl-nav {
		.owl-prev {
			position: absolute;
			left: -50px;
			margin: 0;
			top: 50%;
			background-color: transparent;
			transform: translateY(-50%);
			
			i {
				font-size: 20px;
				color: var(--whiteColor);
				border: 1px solid rgba(255, 255, 255, 0.8);
				width: 40px;
				height: 40px;
				line-height: 38px;
				border-radius: 50%;
				display: inline-block;
				transition: var(--transition);
			}
			&:hover {
				i {
					background-color: var(--mainColor);
					border-color: var(--mainColor);
				}
			}
		}
		.owl-next {
			position: absolute;
			background-color: transparent;
			margin: 0;
			right: -50px;
			top: 50%;
			transform: translateY(-50%);

			i {
				width: 40px;
				height: 40px;
				line-height: 38px;
				border-radius: 50%;
				display: inline-block;
				font-size: 20px;
				color: var(--whiteColor);
				border: 1px solid rgba(255, 255, 255, 0.8);
				transition: var(--transition);
			}
			&:hover {
				i {
					background-color: var(--mainColor);
					border-color: var(--mainColor);
				}
			}
		}
		[class*=owl-]:hover {
			background-color: transparent;
		}
	}
}
.overly-one {
	position: relative;
	transition: var(--transition);

	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		right: -100%;
		width: 100%;
		height: 2px;
		background: var(--mainColor);
		transition: var(--transition);
		opacity: 0;
		z-index: 1;
	}
	&::after {
		content: "";
		position: absolute;
		bottom: -100%;
		left: 0;
		width: 2px;
		height: 100%;
		background: var(--mainColor);
		transition: var(--transition);
		opacity: 0;
	}
	.overly-two {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: -100%;
			width: 100%;
			height: 2px;
			background: var(--mainColor);
			transition: var(--transition);
			opacity: 0;
		}
		&::after {
			content: "";
			position: absolute;
			top: -100%;
			right: 0;
			width: 2px;
			height: 100%;
			background: var(--mainColor);
			transition: var(--transition);
			opacity: 0;
		}
	}
	&:hover {
		transform: translateY(-5px);

		.overly-two {
			&::before {
				left: 30px;
				opacity: 1;
			}
			&::after {
				top: 30px;
				opacity: 1;
			}
		}
		&::before {
			right: 30px;
			opacity: 1;
		}
		&::after {
			bottom: 30px;
			opacity: 1;
		}
	}
}
.team-area {
	position: relative;
	z-index: 1;
}
.team-right-shape {
    z-index: -1;
    position: absolute;
    bottom: 50px;
    right: 50px;
    animation: movebounce 5s linear infinite;
}
.page-title-area {
	position: relative;
	overflow: hidden;
	text-align: center;
	z-index: 1;
	background-color: var(--headingColor);
	padding: {
		top: 100px;
		bottom: 100px;
	};
	&::before {
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		opacity: 0.1;
		content: "";
		position: absolute;
		background: {
			image: url(assets/img/page-bg-shape.png);
			position: center center;
			repeat: no-repeat;
			size: cover;
		};
	}
	.page-title-content {
		position: relative;
		margin-top: -10px;

		h2 {
			margin-bottom: 15px;
			color: var(--whiteColor);
			font-size: 40px;
		}
		ul {
			padding-left: 0;
			list-style-type: none;
			margin-top: 10px;
			margin-bottom: -5px;

			li {
				display: inline-block;
				position: relative;
				font-size: 16px;
				padding-right: 15px;
    			margin-left: 15px;
				font-weight: 500;

				&::before {
					content: "";
					position: absolute;
					top: 6px;
					right: -3px;
					background-color: var(--mainColor);
					width: 1px;
					height: 15px;
					transform: rotate(25deg);
				}
				&:last-child {
					&::before {
						display: none;
					}
				}
				a {
					color: var(--whiteColor);
					
					&:hover {
						color: var(--mainColor);
					}
				}
			}
			.active {
				color: var(--mainColor);
			}
		}
	}
	&.page-title-style-two {
		padding-top: 220px;
		padding-bottom: 100px;
	}
}
.checkout-area {
	.billing-details {
		.form-group {
			.nice-select {
				float: unset;
				line-height: 45px;
				color: var(--bodyColor);
				padding-top: 0;
				padding-bottom: 0;

				.list {
					background-color: var(--whiteColor);
					box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
					border-radius: 0;
					margin-top: 0;
					width: 100%;
					padding-top: 10px;
					padding-bottom: 10px;

					.option {
						transition: 0.5s;
						padding-left: 20px;
						padding-right: 20px;

						&:hover {
							background-color: var(--mainColor) !important;
							color: var(--whiteColor);
						}
					}
					.option.selected {
						background-color: transparent;
					}
				}
				&:after {
					right: 20px;
				}
			}
			.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
				background-color: var(--mainColor) !important;
				color: var(--whiteColor) !important;
			}
		}
	}
}
.faq-accordion {
	.accordion-section {
		margin-bottom: 15px;
		background-color: var(--whiteColor);
		box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.05) !important;
        
		.accordion-header {
			cursor: pointer;
			position: relative;
			font: {
				size: 17px;
				weight: 700;
			};
			padding: {
				top: 20px;
				left: 20px;
				right: 60px;
				bottom: 20px;
			};
			&::before {
				top: 50%;
				right: 20px;
				content: "\ea4a";
				position: absolute;
				transform: translateY(-50%) rotate(0);
				font: {
					family: 'boxicons';
					size: 20px;
				};
			}
			&.open {
				&::before {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
		.accordion-content {
            border-top: 1px solid #eeeeee;
			display: none;
            padding: 20px;

			ul {
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}
				li {
					margin-bottom: 12px;
					color: var(--optionalColor);
					
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			&.open {
				display: block;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.products-details-tab {
	margin-top: 50px;
	
	.nav-tabset {
		border-bottom: 1px solid #eaedff;
		margin-bottom: 30px;
		text-align: center;

		li {
			display: inline-block;
			line-height: initial;
			margin: {
				left: 15px;
				right: 15px;
				bottom: 20px;
			};
			span {
				line-height: 1;
				cursor: pointer;
				font-size: 16px;
				font-weight: 700;
				color: #333333;
				border-bottom: none;
				position: relative;
				display: inline-block;
				transition: var(--transition);
				
				&:hover {
					color: var(--mainColor);
				}
			}
			&.active {
				span {
					color: var(--mainColor);
				}
			}
		}
	}
	.tabs-container {
		.products-details-tab-content {
			p {
				margin-bottom: 20px;
				
				&:last-child {
					margin-bottom: 0;
				}
			}
			.additional-information {
				padding-left: 0;
				margin-bottom: 0;
				list-style-type: none;
				text-align: left;
				
				li {
					border: 1px solid #eaedff;
					border-bottom: none;
					padding: 10px 15px;
					color: #57647c;
					
					&:last-child {
						border-bottom: 1px solid #eaedff;
					}
					span {
						width: 30%;
						display: inline-block;
						color: var(--headingColor);
					}
				}
			}
			h3 {
				margin-bottom: 15px;
			}
			.product-review-form {
				.review-title {
					position: relative;
					
					.rating {
						display: inline-block;
						
						.fas.fa-star {
							color: #ffba0a;
						}
						i {
							color: #ffba0a;
							font-size: 14px;
						}
					}
					p {
						margin-bottom: 0;
						display: inline-block;
						padding-left: 5px;
						line-height: initial;
					}
					.btn {
						position: absolute;
						right: 0;
						bottom: 10px;
						padding: 10px 11px;
						font-size: 11px;
						
						&:focus {
							border: 4px;
							border: none !important;
							box-shadow: none !important;
							background-color: var(--mainColor);
						}
					}
				}
				.review-comments {
					margin-top: 35px;
					
					.review-item {
						margin-top: 30px;
						padding-top: 30px;
						position: relative;
						padding-right: 200px;
						border-top: 1px dashed #eaedff;
						
						.rating {
							.fas.fa-star {
								color: #ffba0a;
							}
							i {
								font-size: 14px;
								color: #ffba0a;
							}
						}
						h3 {
							margin-top: 10px;
							margin-bottom: 10px;
						}
						span {
							margin-bottom: 10px;
							font-size: 13px;
							display: block;
						}
						p {
							margin-bottom: 0;
						}
						.review-report-link {
							right: 0;
							top: 40px;
							color: #57647c;
							position: absolute;
							text-decoration: underline;
							
							&:hover {
								color: #ff4800;
							}
						}
					}
				}
				.review-form {
					margin-top: 30px;
					
					form {
						.form-group {
							margin-bottom: 20px;
							
							label {
								color: #444444;
							}
							textarea {
								padding-top: 15px;
							}
							.form-control {
								font-size: 14px;
							}
						}
						.btn {
							&:focus {
								box-shadow: none;
								border: none;
								border-radius: 0;
							}
						}
					}
				}
			}
		}
	}
}

/* Max width 767px */
@media only screen and (max-width: 767px) {

    body {
        font-size: 14px;
    }
    .container {
        max-width: 100%;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-70 {
        padding-top: 20px;
    }
    .default-btn {
        font-size: 15px;
        padding: 13px 25px;
    }
    h3 {
        font-size: 17px;
        line-height: 1.4;
    }
    .section-title {
        margin-bottom: 30px;
        text-align: center !important;

        span {
            font-size: 14px;
        }
        h2 {
            font-size: 25px;
        }
    }
    p {
        font-size: 14px;
    }
    .overly-one {
        &::before {
            display: none;
        }
        &::after {
            display: none;
        }
        .overly-two {
            &::before {
                display: none;
            }
            &::after {
                display: none;
            }
        }
    }
    .testimonials {
		.owl-nav {
			.owl-prev {
				left: 0;
				top: auto;
				transform: unset;
				bottom: 0;
			}
			.owl-next {
				right: 0;
				top: auto;
				transform: unset;
				bottom: 0;
			}
		}
	}
	.team-right-shape {
		display: none;
	}
    .page-title-area {
        padding: {
            top: 60px;
            bottom: 60px;
        };
        .page-title-content {
            h2 {
                font-size: 30px;
            }
        }
        &.page-title-style-two {
            padding-top: 150px;
            padding-bottom: 70px;
        }
    }
	.about-content {
		.nav-tabset {	
			li {
				margin-right: 15px;
				
				span {
					font-size: 14px;
				}
			}
		}
		.tabs-container {
			ul {
				li {
					font-size: 14px;
				}
			}
		}
	}
	.products-details-tab {
		margin-top: 30px;
		
		.tabs-container {
			.products-details-tab-content {
				.product-review-form {
					.review-comments {
						.review-item {
							padding-right: 0;
						}
					}
				}
			}
		}
	}

}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {

	.container {
        max-width: 540px;
    }

}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {

	.container {
        max-width: 720px;
    }
    .ptb-100 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .pt-100 {
        padding-top: 80px;
    }
    .pb-100 {
        padding-bottom: 80px;
    }
    .pb-70 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 50px;
    }
    .default-btn {
        font-size: 15px;
        padding: 12px 20px;
    }
    .section-title {
        margin-bottom: 45px;
		text-align: center !important;
		
        span {
            font-size: 14px;
        }
        h2 {
            font-size: 30px;
        }
    }
    p {
        font-size: 15px;
    }
    .overly-one {
        &::before {
            display: none;
        }
        &::after {
            display: none;
        }
        .overly-two {
            &::before {
                display: none;
            }
            &::after {
                display: none;
            }
        }
    }
    .testimonials {
		.owl-nav {
			.owl-prev {
				left: 0;
				top: auto;
				transform: unset;
				bottom: 0;
			}
			.owl-next {
				right: 0;
				top: auto;
				transform: unset;
				bottom: 0;
			}
		}
	}
	.team-right-shape {
		display: none;
	}
    .page-title-area {
        padding: {
            top: 90px;
            bottom: 90px;
        };
        .page-title-content {
            h2 {
                font-size: 40px;
            }
        }
        &.page-title-style-two {
            padding-top: 200px;
            padding-bottom: 95px;
        }
    }

}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

	.container {
        max-width: 960px;
    }

}